<template>
  <div v-if="!api.includes('https://telecom0580-clients.bluerocktel.net')">
    <InvoicesHeader
      v-model:searchNumb="searchNumb"
      v-model:searchMonth="searchMonth"
      v-model:searchYear="searchYear"
      v-model:searchTotalWithoutTax="searchTotalWithoutTax"
      v-model:searchTotalWithTax="searchTotalWithTax"
    />
    <InvoiceBody
      :searchMonth="searchMonth"
      :searchYear="searchYear"
      :searchNumb="searchNumb"
      :searchTotalWithTax="searchTotalWithTax"
      :searchTotalWithoutTax="searchTotalWithoutTax"
    />
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";

import InvoiceBody from "../components/InvoiceBody.vue";
import InvoicesHeader from "../components/InvoicesHeader.vue";

import { VueCookieNext } from "vue-cookie-next";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

const api = VueCookieNext.getCookie("API");

export default {
  name: "Invoices",
  props: ["colors"],
  components: {
    InvoiceBody,
    SearchIcon,
    InvoicesHeader,
  },

  data() {
    return {
      account,
      searchMonth: "",
      searchYear: "",
      searchNumb: "",
      searchTotalWithTax: "",
      searchTotalWithoutTax: "",
      api,
    };
  },
};
</script>

<style>
.invoice_account_numb {
  text-align: center;
  padding: 10px;
  margin-right: 56px; /** same width of the sidebar */
}
</style>
