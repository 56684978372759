<template>
  <div
    class="bg-gray-50 border border-gray-200 sm:items-center search_heading sticky top-0 flex flex-wrap justify-between"
  >
    <div class="col-start-2 col-end-3">
      <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
        <SearchFilter
          v-model:search="searchNumb"
          @input="$emit('update:searchNumb', this.searchNumb)"
          :inputPlaceholder="$t('header.searchInvoicePlaceHolder')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";
import SearchFilter from "../components/SearchFilter.vue";

export default {
  name: "InvoicesHeader",
  components: {
    SearchIcon,
    SearchFilter,
  },
  data() {
    return {
      searchMonth: "",
      searchYear: "",
      searchNumb: "",
      searchTotalWithTax: "",
      searchTotalWithoutTax: "",
    };
  },
  methods: {
    disableNumbSearch() {
      let disable = false;
      if (
        this.searchId ||
        this.searchYear ||
        this.searchMonth ||
        this.searchTotalWithTax ||
        this.searchTotalWithoutTax
      ) {
        disable = true;
      }
      return disable;
    },
    disableDateSearch() {
      let disable = false;
      if (
        this.searchId ||
        this.searchNumb ||
        this.searchTotalWithTax ||
        this.searchTotalWithoutTax
      ) {
        disable = true;
      }
      return disable;
    },
    disableTotalTaxSearch() {
      let disable = false;
      if (
        this.searchId ||
        this.searchYear ||
        this.searchMonth ||
        this.searchNumb ||
        this.searchTotalWithoutTax
      ) {
        disable = true;
      }
      return disable;
    },
    disableTotalNoTaxSearch() {
      let disable = false;
      if (
        this.searchId ||
        this.searchYear ||
        this.searchMonth ||
        this.searchTotalWithTax ||
        this.searchNumb
      ) {
        disable = true;
      }
      return disable;
    },
  },
};
</script>

<style></style>
