<template>
  <div class="flex flex-col invoice_table">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:">
          <TablePagination
            :tableData="filteredInvoices()"
            @sort="sort($event)"
            @activeBanner="(activeBanner = true), getInvoices(), (key = !key)"
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import TablePagination from "./TablePagination.vue";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

export default {
  name: "InvoiceBody",
  props: [
    "searchMonth",
    "searchYear",
    "searchNumb",
    "searchTotalWithTax",
    "searchTotalWithoutTax",
  ],
  components: {
    TablePagination,
  },
  data() {
    return {
      invoices: [],
      currentSort: "id",
      currentSortDir: "asc",
      account,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    async getInvoices() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/invoices?customerAccount=${
            this.account
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.isLoading = false;
        this.invoices = res.data;
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedInvoices() {
      return this.invoices.sort((a, b) => {
        let toBeSorted = this.currentSort;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (toBeSorted === "totalWithoutTax" || toBeSorted === "totalWithTax") {
          if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
            return -1 * modifier;
          if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
            return 1 * modifier;
        } else {
          if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
          if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
        }
        return 0;
      });
    },
    numberIsEqual(numb) {
      // if (this.searchNumb) {
      //   return String(numb).includes(String(this.searchTotalWithTax));
      // }
      // if (this.searchNumb) {
      //   return String(numb).includes(String(this.searchTotalWithoutTax));
      // }
      return String(numb).includes(String(this.searchNumb));
    },

    filteredInvoices() {
      return this.sortedInvoices().filter((invoice) => {
        // if (this.searchYear) {
        //   if (this.searchMonth) {
        //     return (
        //       this.getMonth(invoice.date)
        //         .toLowerCase()
        //         .includes(this.searchMonth.toLowerCase()) &&
        //       this.getYear(invoice.date)
        //         .toLowerCase()
        //         .includes(this.searchYear.toLowerCase())
        //     );
        //   }
        //   return this.getYear(invoice.date)
        //     .toLowerCase()
        //     .includes(this.searchYear.toLowerCase());
        // }
        // if (this.searchMonth) {
        //   if (this.searchYear) {
        //     return (
        //       this.getMonth(invoice.date)
        //         .toLowerCase()
        //         .includes(this.searchMonth.toLowerCase()) &&
        //       this.getYear(invoice.date)
        //         .toLowerCase()
        //         .includes(this.searchYear.toLowerCase())
        //     );
        //   }
        //   return this.getMonth(invoice.date)
        //     .toLowerCase()
        //     .includes(this.searchMonth.toLowerCase());
        // }

        // if (this.searchNumb) {
        //   return invoice.number
        //     .toLowerCase()
        //     .includes(this.searchNumb.toLowerCase());
        // }
        // if (this.searchTotalWithTax) {
        //   return this.numberIsEqual(invoice.totalWithTax);
        // }
        // if (this.searchTotalWithoutTax) {
        //   return this.numberIsEqual(invoice.totalWithoutTax);
        // }
        return (
          invoice.date.toLowerCase().includes(this.searchNumb.toLowerCase()) ||
          invoice.number
            .toLowerCase()
            .includes(this.searchNumb.toLowerCase()) ||
          this.getMonth(invoice.date)
            .toLowerCase()
            .includes(this.searchNumb.toLowerCase()) ||
          this.getYear(invoice.date)
            .toLowerCase()
            .includes(this.searchNumb.toLowerCase()) ||
          this.numberIsEqual(invoice.totalWithTax) ||
          this.numberIsEqual(invoice.totalWithoutTax)
        );
      });
    },
    getYear(date) {
      const dateCreated = new Date(date);
      const formatedDate = dateCreated.getFullYear();

      return String(formatedDate);
    },
    getMonth(date) {
      const dateCreated = new Date(date);
      const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      return String(formatedDate);
    },
  },
  mounted() {
    this.getInvoices();
  },
  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style>
.invoice_table {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
